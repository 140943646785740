<template>
  <div :class="!this.urseInfo?'guaranteeSlip':'uploadSlip'">
    <heas title="保单识别" />
    <div
      class="content"
      v-if="!this.urseInfo"
    >
      <img
        class="photo"
        :src="photo"
        alt=""
      >
      <input
        type="file"
        class="upload"
        ref="inputer"
        mutiple="mutiple"
        accept="image/*"
        @change="upLoadHeadImg($event)"
      >
      <p class="text">添加保单</p>
    </div>
    <!-- 用户信息 -->
    <div
      class="userInfo"
      v-if="this.urseInfo"
    >
      <div class="basicInfoBox">
        <p class="basicInfoText">基本信息</p>
        <div class="basicData">
          <p class="basicTitle">保单类型:</p>
          <p class="basicTitle">寿险</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">保险公司名称:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo && this.urseInfo.companyName"
          >{{this.urseInfo.companyName}}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">保单号或者合同号:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo && this.urseInfo.policyNo"
          >
            {{this.urseInfo.policyNo}}
          </p>
        </div>
        <div class="basicData">
          <p class="basicTitle">保单承保日期或成交日:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo && this.urseInfo.underWriteDate "
          >{{this.urseInfo.underWriteDate }}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">保单生效日期:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo && this.urseInfo.effectiveStartDate "
          >{{this.urseInfo.effectiveStartDate  }}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">交费方式:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo && this.urseInfo.chargePeriodType"
          >{{this.urseInfo.chargePeriodType }}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">总保费:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo && this.urseInfo.premium"
          >{{this.urseInfo.premium}}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">币种:</p>
          <p class="basicTitle">人民币</p>
        </div>
      </div>
      <!-- 投保人信息 -->
      <div class="policyholder">
        <p class="policyholderText">投保人信息</p>
        <div class="basicData">
          <p class="basicTitle">投保人姓名:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo.insurer && this.urseInfo.insurer.name"
          >{{this.urseInfo.insurer.name}}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">投保人性别:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo.insurer && this.urseInfo.insurer.gender"
          >{{this.urseInfo.insurer.gender }}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">投保人年龄:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo.insurer && this.urseInfo.insurer.age"
          >{{this.urseInfo.insurer.age}}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">投保人出生日期:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo.insurer && this.urseInfo.insurer.birth"
          >{{this.urseInfo.insurer.birth}}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">投保人证件类型:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo.insurer && this.urseInfo.insurer.certiType"
          >{{this.urseInfo.insurer.certiType}}</p>
        </div>
        <div class="basicData">
          <p class="basicTitle">投保人证件号码:</p>
          <p
            class="basicTitle"
            v-if="this.urseInfo.insurer && this.urseInfo.insurer.certiCode"
          >{{this.urseInfo.insurer.certiCode}}</p>
        </div>
      </div>
      <!-- 被保人信息 -->
      <div class="insuredList">
        <p class="insuredText">被保人信息</p>
        <ul class="uls">
          <li
            class="list"
            v-for="(item,index) in this.urseInfo.insuredList"
            :key="index"
          >
            <div class="basicData">
              <p class="basicTitle">被保险人姓名:</p>
              <p
                class="basicTitle"
                v-if="item.name"
              >{{item.name}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">职业与类别:</p>
              <p
                class="basicTitle"
                v-if="item.career"
              >{{item.career}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">年龄:</p>
              <p
                class="basicTitle"
                v-if="item.age"
              >{{item.age}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">被保险人性别:</p>
              <p
                class="basicTitle"
                v-if="item.gender"
              >{{item.gender}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">被保险人证件类型:</p>
              <p
                class="basicTitle"
                v-if="item.productType"
              >{{item.productType}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">被保险人证件号码:</p>
              <p
                class="basicTitle"
                v-if="item.certiCode"
              >{{item.certiCode}}</p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 受益人信息 -->
      <div class="benefitList">
        <p class="benefitText">受益人信息</p>
        <ul class="uls">
          <li
            class="list"
            v-for="(item,index) in this.urseInfo.benefitList"
            :key="index"
          >
            <div class="basicData">
              <p class="basicTitle">受益人:</p>
              <p
                class="basicTitle"
                v-if="item.benefitName"
              >{{item.benefitName}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">受益人类型:</p>
              <p
                class="basicTitle"
                v-if="item.benefitType"
              >{{item.benefitType}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">受益比例:</p>
              <p
                class="basicTitle"
                v-if="item.percentOfBenefit"
              >{{item.percentOfBenefit}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">受益人顺位:</p>
              <p
                class="basicTitle"
                v-if="item.benefitLevel"
              >{{item.benefitLevel}}</p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 险种信息 -->
      <div class="productList">
        <p class="productText">险种信息</p>
        <ul class="uls">
          <li
            class="list"
            v-for="(item,index) in this.urseInfo.productList"
            :key="index"
          >
            <div class="basicData">
              <p class="basicTitle">险种名称:</p>
              <p
                class="basicTitle"
                v-if="item.name"
              >{{item.name}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">基本保额:</p>
              <p
                class="basicTitle"
                v-if="item.basicAmnt"
              >{{item.basicAmnt}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">期交保费:</p>
              <p
                class="basicTitle"
                v-if="item.prem"
              >{{item.prem}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">交费期间:</p>
              <p
                class="basicTitle"
                v-if="item.payYears"
              >{{item.payYears}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">保障期间:</p>
              <p
                class="basicTitle"
                v-if="item.coverYears"
              >{{item.coverYears}}</p>
            </div>
            <div class="basicData">
              <p class="basicTitle">生效日:</p>
              <p
                class="basicTitle"
                v-if="item.effectiveStartDate"
              >{{item.effectiveStartDate}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFiles } from "@/fetch/api/chat.js";
import Header from "@/components/Header";
export default {
  data() {
    return {
      photo: require("../../assets/images/addPhoto.png"),
      urseInfo: null,
      a: "",
    };
  },
  mounted() {},
  methods: {
    upLoadHeadImg(val) {
      let _this = this;
      _this.$toast.loading({
        duration: 0,
        message: "图片上传中...",
      });
      let file = val.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        uploadFiles({ file: e.target.result.slice(23) }).then((res) => {
          if (res.data.status == 200) {
            _this.urseInfo = res.data.data;
            _this.$toast.clear();
          } else {
            _this.photo = require("../../assets/images/addPhoto.png");
            _this.$toast.clear();
            _this.$toast(res.data.message);
          }
        });
      };
    },
  },
  components: {
    heas: Header,
  },
};
</script>

<style scoped lang="scss">
.uploadSlip {
  background: #f1f1f1;
  position: absolute;
  min-height: 100%;
  padding-top: 2rem;
  padding-bottom: 1.07rem;
}
.uploadSlip,
.guaranteeSlip {
  width: 100%;
  .content {
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    position: relative;
    padding-top: 3.15rem;
    .photo {
      width: 3.49rem;
      height: 3.49rem;
      margin: 0 auto;
    }
    .upload {
      position: absolute;
      left: 32%;
      top: 3.15rem;
      opacity: 0;
      width: 3.49rem;
      height: 3.49rem;
    }
    .text {
      font-size: 0.4rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 0.48rem;
    }
  }
  .addPhoto,
  .noAddPhoto {
    width: 6.11rem;
    height: 1.17rem;
    background: #4a86fc;
    border-radius: 0.67rem;
    font-size: 0.45rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    bottom: 2.56rem;
    transform: translate(-50%);
  }
  .noAddPhoto {
    opacity: 0.2;
  }
  .userInfo {
    width: 100%;
    .basicInfoBox,
    .policyholder,
    .insuredList,
    .benefitList,
    .productList {
      width: 90%;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0rem 0.03rem 0.53rem 0rem rgba(0, 0, 0, 0.06);
      border-radius: 0.27rem;
      padding: 0.53rem;
      .basicInfoText,
      .policyholderText,
      .insuredText,
      .benefitText,
      .productText {
        font-size: 0.45rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        padding-bottom: 0.2rem;
      }
      .basicData {
        display: flex;
        justify-content: space-between;
        margin-top: 0.3rem;
        .basicTitle {
          font-size: 0.43rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
        }
      }
    }
    .policyholder,
    .insuredList,
    .benefitList,
    .productList {
      margin-top: 0.4rem;
      .list {
        padding-bottom: 0.4rem;
        border-bottom: 1px solid #eeeeee;
      }
    }
    .list:last-child {
      border: none;
      padding-bottom: 0rem;
    }
  }
}
</style>
